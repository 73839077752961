$dark: black;
$light: white;
$primary: #012F3F;
$primary-light: lighten($primary, 20%);
$secondary: rgb(107, 160, 196);
$secondary-dark: lightgrey;
$invalid: #dc3545;
$disabled-button: #687289;

$smallScreen: 600px;
$mediumScreen: 960px;
$largeScreen: 1300px;
$background: #F7F8FC;


// $sidebarWidth: 200px;
$footerHeight: 23px;
$headerHeight: 44px;

$ocean-blue: #0462F8;
$ocean-blue-light: lighten($ocean-blue, 20%);
$gray: #adadad;
$gray-light: #F6F4F4;
$gray-dark: #636264;
$orange: #ED634A;
$orange-light: lighten($orange, 20%);
$orange-dark: darken($orange, 20%);


$unauthorizedAccess: yellow;
$sosGuestInRoom:lightpink;
$sosCardNotDeposited: purple;
$guestCardDeposited: green;
$staffCardDeposited: cyan;
$roomRented: Tan;
$roomNotRented: lightgray;

$access-control-main-color: #585858;
$rooms-background: #D9D9D9;
