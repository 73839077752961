/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */




:root {

    background: #190D34;
 
   }
 
   .ion-color-orange {
    --ion-color-base: #ED634A;
    --ion-color-base-rgb: rgb(237, 99, 74);
    --ion-color-contrast: #ffffff;
    --ion-color-contrast-rgb: 255, 255, 255;
    --ion-color-shade: #be4f3b;
    --ion-color-tint: #f1826e;
  }
 
 
  .ion-color-ocean-blue {
   --ion-color-base: #0462F8;
   --ion-color-base-rgb: rgb(4, 98, 248);
   --ion-color-contrast: #ffffff;
   --ion-color-contrast-rgb: 255, 255, 255;
   --ion-color-shade: #0456da;
   --ion-color-tint: #1d72f9;
 }
 