.loading-indicator {
  display: inline-block;
  position: relative;
  width: 80px;
  padding-top: 20px;
}
.loading-indicator div {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-indicator div:nth-child(1) {
  left: 8px;
  animation: loading-indicator1 0.6s infinite;
}
.loading-indicator div:nth-child(2) {
  left: 8px;
  animation: loading-indicator2 0.6s infinite;
}
.loading-indicator div:nth-child(3) {
  left: 32px;
  animation: loading-indicator2 0.6s infinite;
}
.loading-indicator div:nth-child(4) {
  left: 56px;
  animation: loading-indicator3 0.6s infinite;
}
@keyframes loading-indicator1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-indicator3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading-indicator2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/*
<div class="loading-indicator"><div></div><div></div><div></div><div></div></div> */

.lds-dual-ring {
  display: inline-block;
  // width: 80px;
  // height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 50px;
  height: 50px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: black transparent black transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// <div class="lds-dual-ring"></div>

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 40%;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: white;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


//smaller spinner
.lds-spinner-small {
  color: official;
  display: inline-block;
  position: relative;
  width: 26px;
  height: 26px;
  margin-top: 8px;
}
.lds-spinner-small  div {
  transform-origin: 13px 13px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner-small  div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1px;
  left: 12px;
  width: 2px;
  height: 6px;
  border-radius: 20%;
  background: black;
}
.lds-spinner-small  div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner-small  div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner-small  div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner-small  div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner-small  div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner-small  div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner-small  div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner-small  div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner-small  div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner-small  div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner-small  div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner-small  div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

//smaller spinner white
.lds-spinner-small-wh {
  color: official;
  display: inline-block;
  position: relative;
  width: 26px;
  height: 26px;
  margin-top: 8px;
}
.lds-spinner-small-wh  div {
  transform-origin: 13px 13px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner-small-wh  div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1px;
  left: 12px;
  width: 2px;
  height: 6px;
  border-radius: 20%;
  background: white;
}
.lds-spinner-small-wh  div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner-small-wh  div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner-small-wh  div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner-small-wh  div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner-small-wh  div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner-small-wh  div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner-small-wh  div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner-small-wh  div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner-small-wh  div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner-small-wh  div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner-small-wh  div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner-small-wh  div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}


//medium spinner
.lds-spinner-md {
  color: official;
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-spinner-md div {
  transform-origin: 25px 25px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner-md div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 2px;
  left: 24px;
  width: 4px;
  height: 12px;
  border-radius: 20%;
  background: black;
}
.lds-spinner-md  div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner-md  div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner-md  div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner-md  div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner-md  div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner-md  div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner-md  div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner-md  div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner-md  div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner-md  div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner-md  div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner-md  div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
