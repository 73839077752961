@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?qcaz9v');
  src:  url('fonts/icomoon.eot?qcaz9v#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?qcaz9v') format('truetype'),
    url('fonts/icomoon.woff?qcaz9v') format('woff'),
    url('fonts/icomoon.svg?qcaz9v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-align: center;
  padding-top: 2px;
  align-items: center;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]::before, [class*=" icon-"]::before {
  vertical-align: middle !important;
}


.icon-access-log:before {
  content: "\e900";
}
.icon-acknowledge:before {
  content: "\e901";
}
.icon-add:before {
  content: "\e902";
}
.icon-alarm_log:before {
  content: "\e903";
}
.icon-alert:before {
  content: "\e904";
}
.icon-arrow_r:before {
  content: "\e905";
}
.icon-arrow_up:before {
  content: "\e906";
}
.icon-audit-log:before {
  content: "\e907";
}
.icon-automation:before {
  content: "\e908";
}
.icon-back_arrow:before {
  content: "\e909";
}
.icon-bathroom:before {
  content: "\e90a";
}
.icon-battery:before {
  content: "\e90b";
}
.icon-broom:before {
  content: "\e90c";
}
.icon-calendar:before {
  content: "\e90d";
}
.icon-card:before {
  content: "\e90e";
}
.icon-checkmark:before {
  content: "\e90f";
}
.icon-cleaned:before {
  content: "\e910";
}
.icon-cleaning-clean:before {
  content: "\e911";
}
.icon-cleaning-failed-ispection .path1:before {
  content: "\e912";
  color: rgb(255, 0, 0);
}
.icon-cleaning-failed-ispection .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 0, 0);
}
.icon-cleaning-failed-ispection .path3:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 0, 0);
}
.icon-cleaning-failed-ispection .path4:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-cleaning-inspected .path1:before {
  content: "\e916";
  color: rgb(41, 146, 11);
}
.icon-cleaning-inspected .path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(41, 146, 11);
}
.icon-cleaning-inspected .path3:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(41, 146, 11);
}
.icon-cleaning-inspected .path4:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-clear:before {
  content: "\e91a";
}
.icon-client:before {
  content: "\e91b";
}
.icon-clock:before {
  content: "\e91c";
}
.icon-clock_add:before {
  content: "\e91d";
}
.icon-compact:before {
  content: "\e91e";
}
.icon-copy:before {
  content: "\e91f";
}
.icon-corridor:before {
  content: "\e920";
}
.icon-delete:before {
  content: "\e921";
}
.icon-deny:before {
  content: "\e922";
}
.icon-details:before {
  content: "\e923";
}
.icon-dnd:before {
  content: "\e924";
}
.icon-door:before {
  content: "\e925";
}
.icon-door_opened_status:before {
  content: "\e926";
}
.icon-edit:before {
  content: "\e927";
}
.icon-fan:before {
  content: "\e928";
}
.icon-filter:before {
  content: "\e929";
}
.icon-fire:before {
  content: "\e92a";
}
.icon-flood-alarm:before {
  content: "\e92b";
}
.icon-floor-heating:before {
  content: "\e92c";
}
.icon-forbidden:before {
  content: "\e92d";
}
.icon-full:before {
  content: "\e92e";
}
.icon-hvac-high:before {
  content: "\e92f";
}
.icon-hvac-low:before {
  content: "\e930";
}
.icon-hvac-mid:before {
  content: "\e931";
}
.icon-inspect:before {
  content: "\e932";
}
.icon-key:before {
  content: "\e933";
}
.icon-language_bubble:before {
  content: "\e934";
}
.icon-lightbulb:before {
  content: "\e935";
}
.icon-list:before {
  content: "\e936";
}
.icon-live_log:before {
  content: "\e937";
}
.icon-location:before {
  content: "\e938";
}
.icon-lock:before {
  content: "\e939";
}
.icon-locked:before {
  content: "\e93a";
}
.icon-minibar:before {
  content: "\e93b";
}
.icon-minimal:before {
  content: "\e93c";
}
.icon-moon_and_sun:before {
  content: "\e93d";
}
.icon-noun_clock:before {
  content: "\e93e";
}
.icon-offline:before {
  content: "\e93f";
}
.icon-paste:before {
  content: "\e940";
}
.icon-play:before {
  content: "\e941";
}
.icon-plus:before {
  content: "\e942";
}
.icon-radiator:before {
  content: "\e943";
}
.icon-reader:before {
  content: "\e944";
}
.icon-reader_deny:before {
  content: "\e945";
}
.icon-refresh:before {
  content: "\e946";
}
.icon-reset:before {
  content: "\e947";
}
.icon-room:before {
  content: "\e948";
}
.icon-room-armed:before {
  content: "\e949";
}
.icon-rooms:before {
  content: "\e94a";
}
.icon-room-status:before {
  content: "\e94b";
}
.icon-safe:before {
  content: "\e94c";
}
.icon-search:before {
  content: "\e94d";
}
.icon-settings:before {
  content: "\e94e";
}
.icon-snowflake:before {
  content: "\e94f";
}
.icon-staff:before {
  content: "\e950";
}
.icon-sunrise:before {
  content: "\e951";
}
.icon-sunset:before {
  content: "\e952";
}
.icon-temp:before {
  content: "\e953";
}
.icon-temperature-difference-alarm:before {
  content: "\e954";
}
.icon-three-vert-dots:before {
  content: "\e955";
}
.icon-transfer:before {
  content: "\e956";
}
.icon-unlocked:before {
  content: "\e957";
}
.icon-unplug:before {
  content: "\e958";
  background-color: red;
}
.icon-user:before {
  content: "\e959";
}
.icon-user_add:before {
  content: "\e95a";
}
.icon-user_list:before {
  content: "\e95b";
}
.icon-users:before {
  content: "\e95c";
}
.icon-welcome-light:before {
  content: "\e95d";
}
.icon-window:before {
  content: "\e95e";
}
.icon-window-closed:before {
  content: "\e95f";
}
.icon-window-open:before {
  content: "\e960";
}
.icon-x:before {
  content: "\e961";
}
.icon-group:before {
  content: "\e962";
}
.icon-hvac:before {
  content: "\e963";
}
.icon-analytics:before {
  content: "\e964";
}
.icon-sun:before {
  content: "\e965";
}
.icon-encoder:before {
  content: "\e966";
}
.icon-question:before {
  content: "\e967";
}
.icon-humidity:before {
  content: "\e968";
}
.icon-co2:before {
  content: "\e969";
}
.icon-retro-mode:before {
  content: "\e96a";
}
.icon-technician:before {
  content: "\e96b";
}
.icon-auto-mode:before {
  content: "\e96c";
}
.icon-split-view:before {
  content: "\e96d";
}

