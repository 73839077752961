@import './variables.scss';





.input-switch {
  display: inline-block;
}
.input-switch .input {
  position: absolute;
  visibility: hidden;
}
.input-switch .input + .switch {
  position: relative;
  height: 10px;
  width: 45px;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 10px;
  background-color: $primary;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s 0.05s;
}
.input-switch .input + .switch::before, .input-switch .input + .switch::after {
  content: "";
  position: absolute;
  transition: 0.35s;
}
.input-switch .input + .switch::before {
  top: 0;
  left: 0;
  height: 100%;
  width: 20%;
  border-radius: 10px;
  background-color: $secondary;
}
.input-switch .input + .switch::after {
  left: 5px;
  top: 50%;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: none;
  background-color: $primary;
  transform: translateY(-50%);
  opacity: 1;
}
.input-switch .input + .switch:hover::after {
  transform: translateY(-50%) scale(1.1);
}
.input-switch .input:checked + .switch {
  background-color: $primary;
}
.input-switch .input:checked + .switch::before {
  width: calc(100% - 5px);
  background-color: $secondary;
}
.input-switch .input:checked + .switch::after {
  left: calc(100% - (20px + 5px));
  background-color: $secondary;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $orange;
}

input:focus + .slider {
  box-shadow: 0 0 1px  #2E282A;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 50%;
}
