.content-header {
    margin: 10px;
    //margin-left: 50px;
    padding-bottom:20px;
    border-bottom: 1.7px solid $secondary;
    & h1 {
        letter-spacing: 2px;
    }
    & h2 {
        letter-spacing: 2px;
        font-weight: 400;
    }
}

.content-header-facility {
    margin: 30px 5px 5px 5px;
    padding-bottom: 15px;
    border-bottom: 1.7px solid $secondary;
    & h1 {
        letter-spacing: 2px;
    }
    & h2 {
        letter-spacing: 2px;
        font-weight: 400;
    }
}


.content-header-sub {
    margin: 10px 0;
    //margin-left: 50px;
    border-bottom: 1px solid $secondary-dark;
    & h1 {
        letter-spacing: 2px;
    }
    & h2 {
        letter-spacing: 2px;
        font-weight: 400;
        font-size: 1.2rem;
    }
}

.content-container {
    margin: 30px 100px;
}

.content-list-row {
    border-bottom: 1px solid $secondary-dark;
    margin: 20px;
    font-size: 1.1em;
}

.content-list-row-rules {
    border-bottom: 1px solid $secondary-dark;
    padding-bottom: 10px;
    margin: 20px;
    font-size: 1.1em;
}

.content-list-row-user-list {
    border-bottom: 1px solid $secondary-dark;
    margin: 30px 100px;
    font-size: 1.1em;
}

.content-list-row p{
    width: 200px;
}

.square-secondary {
    --border-radius:0px;
    --background: rgb(107, 160, 196);
  }

.content-form-group {
    display: flex;
    flex-direction: column;
    & label {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #9FA2B4;
    }
    & input {
        box-sizing: border-box;
        background: #FCFDFE;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        padding: 10px 10px 10px 10px;
    }
    & select {
        background-color: #FCFDFE;
        min-width: 250px;
        padding: 5px;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        height: 40px;
    }
    @media screen and (max-width: $mediumScreen) {
        flex-direction: column;
      }
}

.content-form-group-number {
    display: flex;
    flex-direction: column;
    & label {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #9FA2B4;
    }
    & input {
        box-sizing: border-box;
        background: #FCFDFE;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        padding: 10px 10px 10px 10px;
        width: 80px;
    }
    & select {
        background-color: #FCFDFE;
        min-width: 250px;
        padding: 5px;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        height: 40px;
    }
    @media screen and (max-width: $mediumScreen) {
        flex-direction: column;
      }
}

.content-form-group input::placeholder {

}

.content-button-container {
    float: right;
  }

.invalid-input {
    margin-top: .25rem;
    font-size: 80%;
    padding: 7px;
    color: #dc3545;
}

.is-invalid {
    border-color: #dc3545!important;
    border-width: 3px !important
}

.checkbox-container {
    font-size: 1rem;
  }

.checkbox-container input{
    border: grey solid 1px;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
}

// .content-search{
//     background-color: rgb(245, 245, 245);
//     color: $dark;
//     border: none;
//     background-image: url('/assets/images/search.svg');
//     background-repeat: no-repeat;
//     background-position: right;
//     background-position-y: 6px;
//     outline: none;
//     height: 35px;
//     margin-left: auto;
//     margin-right: 10px;
//     margin-bottom: 10px;
//     padding: 10px;
// }


// .content-search-sm{
//     background-size: 18px;
//     height: 20px;
//     width: 100px;
//   }

@media screen and (max-width: 1100px) {
    .content-container {
        margin: 0;
    }
    .content-search{
        width: 100px;
      }

    .content-list-row p{
        width: 130px;
    }
  }

.filter-box {
  display:inline-block;
  color: $primary;
  border: 1px solid $primary;
  border-radius: 10px;
  background-color: $secondary-dark;
  box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
  vertical-align:middle;
  padding: 5px;
  padding-left: 20px;
  text-align: center;
  margin-bottom: 5px;
}

.custom-select {
  font-size: 1rem;
  font-weight: 400;
  margin: 2px;
  border: 1px solid $primary;
  padding: 5px;
  border-radius: 5px;
  height: 31px
}
