.logs-table {
  border-collapse: collapse;
  width: 100%;
  table-layout:fixed;
  }


.logs-table td {
  color: black;
  text-align: center;
  padding: 8px;
}

.logs-table th {
  background-color: $background;
  color: black;
  text-align: center;
  text-transform: uppercase;
  padding:10px
}

.logs-table tr {
  background-color: #f5f5f5;
  border-bottom: 0.5px solid LightGray;
}

